import React, { useState } from 'react';
import i18n, { languages } from 'i18n';
import { Dropdown } from 'react-bootstrap';
import { CircleFlag, CircleFlagLanguage } from 'react-circle-flags';

export default function LanguageSelector() {
	const [currentLanguage, setCurrentLanguage] = useState(
		languages.find((lang) => lang.lang === i18n.language)
	);

	function setLanguage(lang: string) {
		i18n.changeLanguage(lang);
		setCurrentLanguage(languages.find((l) => l.lang === lang));
	}

	return (
		<Dropdown align="end" id="dropdown-language">
			<Dropdown.Toggle variant="outline-primary" className="d-flex align-items-center">
				{currentLanguage?.code.includes('-') ? (
					<CircleFlagLanguage languageCode={currentLanguage.code} height="18" className="me-1" />
				) : (
					<CircleFlag countryCode={currentLanguage?.code ?? 'en-us'} height="18" className="me-1" />
				)}
				<i className="ms-2 bi-chevron-down" />
			</Dropdown.Toggle>

			<Dropdown.Menu>
				{languages.map((lang, i_lang) => (
					<Dropdown.Item key={i_lang} onClick={() => setLanguage(lang.lang)}>
						{lang.code.includes('-') ? (
							<CircleFlagLanguage languageCode={lang.code} height="18" className="me-2 mb-1" />
						) : (
							<CircleFlag countryCode={lang.code} height="18" className="me-2 mb-1" />
						)}
						{lang.name}
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	);
}

import React, { useRef, useState, useEffect, useMemo } from 'react';
import { DataSaveStatus, StockItem } from 'components/Forms/ProductsForm';
import { StockItemOption } from 'components/Forms/Promotion/PromotionProduct';
import { useSelector } from 'react-redux';
import { RootState } from 'state/store';
import PromotionCell from 'components/Forms/Promotion/PromotionCell';
import { Modal } from 'react-bootstrap';

export default function PromotionStockItem(props: {
	template: string;
	productId: number;
	productIndex: number;
	i: number;
	item: StockItem;
	disabled: boolean;
	setValue: (
		item_id: number | null,
		tab_name: string | null,
		input_name: string,
		value: string,
		rounding?: number,
		country_index?: number
	) => void;
	setDataSaveStatus?: (s: DataSaveStatus) => void;
	removeStockItem: (itemIndex: number) => void;
	countries: string[];
}) {
	const [stockItemsDropdown, setStockItemsDropdown] = useState([] as StockItemOption[]);
	const stockItemOptions = useSelector((state: RootState) => state.stockItem.stockItems) ?? [];
	const [focusedStockItemOption, setFocusedStockItemOption] = useState(-1);
	const [isFocused, setIsFocused] = useState(false);
	const [showCodeModal, setShowCodeModal] = useState(false);

	function pickStockItems(q: string) {
		const si = stockItemOptions
			.filter((s) => s.c_item.startsWith(q) || s.n_item.toLowerCase().includes(q.toLowerCase()))
			.slice(0, 10);
		setStockItemsDropdown(si);
	}

	function checkInputEqualsStockItem(value: string) {
		const foundItem = stockItemOptions.find((item) => item.c_item === value);
		setStockItem(foundItem);
		return !!foundItem;
	}

	const stockItemOptionsRefs = useRef([] as HTMLLIElement[]);
	const stockItemCodeInputRef = useRef(null as HTMLInputElement | null);

	function onKeyDown(e: React.KeyboardEvent, stockItemTarget = null as StockItemOption | null) {
		if (stockItemTarget) {
			// the target is one of the list items
			e.preventDefault();
			if (e.key === 'Enter') {
				setStockItem(stockItemTarget);
			}
		}

		let newFocusedItemOption = focusedStockItemOption;
		if (e.key === 'ArrowDown') {
			newFocusedItemOption = Math.min(stockItemsDropdown.length - 1, focusedStockItemOption + 1);
			stockItemOptionsRefs.current[newFocusedItemOption]?.focus();

			setFocusedStockItemOption(newFocusedItemOption);
		} else if (e.key === 'ArrowUp') {
			newFocusedItemOption = Math.max(-1, focusedStockItemOption - 1);
			if (newFocusedItemOption !== -1) {
				stockItemOptionsRefs.current[newFocusedItemOption].focus();
			} else {
				stockItemCodeInputRef.current?.focus();
			}

			setFocusedStockItemOption(newFocusedItemOption);
		} else {
			setFocusedStockItemOption(-1);
		}

		if (['Tab', 'Escape'].includes(e.key)) {
			setIsFocused(false);
			stockItemCodeInputRef.current?.focus();
		}
	}

	function setStockItem(stockItem: StockItemOption | undefined) {
		if (stockItem) {
			props.setValue(props.i, null, 'c_item', stockItem ? stockItem.c_item : '');
			setShowCodeModal(false);
		}

		if (stockItem !== undefined) {
			setIsFocused(false);
		}
	}

	function isStockItemDropdownClicked(target: HTMLElement | null) {
		while (target) {
			if (target.classList.contains('stock-item-dropdown')) {
				return true;
			}
			target = target.parentElement;
		}
		return false;
	}

	function useHandleDocumentClick() {
		useEffect(() => {
			function handleClickOutside(event: MouseEvent) {
				const target = event.target as HTMLElement;
				if (!isStockItemDropdownClicked(target)) {
					setIsFocused(false);
				}
			}

			document.addEventListener('click', handleClickOutside);
			return () => {
				document.removeEventListener('click', handleClickOutside);
			};
		}, []);
	}

	const base_price = useMemo(() => {
		if (props.countries.length === 1) {
			const c = props.item.countries.find((c) => c.i_country === props.countries[0]);
			if (typeof c?.base_price === 'number') {
				return { value: c.base_price.toFixed(2), approved: c.approved };
			}
			return { value: c?.base_price ?? '0.00', approved: c?.approved ?? false };
		} else if (props.item.countries.every((c) => c.approved)) {
			return { value: 'Approved', approved: true };
		} else {
			return { value: 'Not approved', approved: false };
		}
	}, [props.countries, props.item]);

	const retail_price = useMemo(() => {
		if (props.countries.length === 1) {
			const c = props.item.countries.find((c) => c.i_country === props.countries[0]);
			if (typeof c?.retail_price === 'number') {
				return { value: c.retail_price.toFixed(2), enabled: true };
			}
			return { value: c?.retail_price ?? '0.00', enabled: true };
		} else if (props.item.countries.every((c) => c.approved)) {
			return { value: 'Approved', enabled: true };
		} else {
			return { value: 'Not approved', enabled: false };
		}
	}, [props.countries, props.item]);

	const n_item = useMemo(() => {
		if (props.countries.length === 1) {
			const c = props.item.countries.find((c) => c.i_country === props.countries[0]);
			return c?.product_name ?? '';
		} else {
			return props.item.n_item;
		}
	}, [props.countries, props.item]);

	useHandleDocumentClick();

	return (
		<div className="mt-2 row">
			<div className="col-1 px-1">
				<PromotionCell
					template={props.template}
					productIndex={props.productIndex}
					productId={props.productId}
					name={'quantity'}
					type={'number'}
					min={1}
					required={true}
					disabled={props.disabled}
					defaultValue={props.item.quantity?.toString()}
					setValue={props.setValue}
					itemId={props.i}
					rounding={undefined}
					setDataSaveStatus={props.setDataSaveStatus}
				/>
			</div>
			<div className="col-5 px-1 position-relative">
				<div className="input-group stock-item-group">
					<button
						className="form-control stock-item-code-trigger"
						type="button"
						onClick={() => setShowCodeModal(true)}>
						{props.item.c_item}
					</button>
					<Modal
						show={showCodeModal}
						onShow={() => (stockItemCodeInputRef.current as HTMLInputElement).focus()}
						onHide={() => setShowCodeModal(false)}>
						<Modal.Header closeButton></Modal.Header>

						<Modal.Body>
							<span className="ms-1">Stock item code</span>
							<input
								id={props.template + '.' + props.productIndex + '.' + props.i + '.c_item'}
								className="form-control"
								ref={stockItemCodeInputRef}
								name={props.template + '.' + props.productIndex + '.' + props.i + '.c_item'}
								type="text"
								required={true}
								value={props.item.c_item}
								disabled={props.disabled}
								autoComplete="off"
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									if (!checkInputEqualsStockItem(e.target.value)) {
										props.setValue(props.i, null, 'c_item', e.target.value);
										setIsFocused(true);
									}
									setStockItemsDropdown([]);
									pickStockItems(e.target.value);
								}}
								onKeyDown={(e) => onKeyDown(e)}
							/>
							{isFocused && stockItemsDropdown.length > 0 && (
								<ul className="stock-item-dropdown">
									{stockItemsDropdown.map((stockItem, j) => (
										<li
											tabIndex={0}
											ref={(el) => (stockItemOptionsRefs.current[j] = el as HTMLLIElement)}
											className="stock-item-dropdown-content font-small py-1 px-2"
											onClick={() => setStockItem(stockItem)}
											onKeyDown={(e) => onKeyDown(e, stockItem)}
											key={j}>
											<b>{stockItem.c_item}</b> - {stockItem.n_item}
										</li>
									))}
								</ul>
							)}
						</Modal.Body>
					</Modal>
					{props.countries.length === 1 ? (
						<PromotionCell
							template={props.template}
							productIndex={props.productIndex}
							productId={props.productId}
							name={'product_name'}
							type={'text'}
							required={true}
							setValue={props.setValue}
							itemId={props.i}
							country={props.countries[0]}
							defaultValue={n_item}
						/>
					) : (
						<PromotionCell
							template={props.template}
							productIndex={props.productIndex}
							productId={props.productId}
							name={'n_item'}
							type={'text'}
							required={true}
							setValue={props.setValue}
							itemId={props.i}
							defaultValue={n_item}
							disabled={true}
							tooltip={true}
						/>
					)}
				</div>
			</div>
			<div className="col-2 px-1">
				<PromotionCell
					template={props.template}
					productIndex={props.productIndex}
					productId={props.productId}
					name="srp"
					type="number"
					customClass="text-end"
					required={true}
					disabled={true}
					defaultValue={props.item.srp?.toString()}
					setValue={props.setValue}
					itemId={props.i}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						e.preventDefault();
					}}
					min={0}
					step={0.01}
				/>
			</div>
			<div className="col-3">
				<div className="row">
					{props.countries.length === 1 ? (
						<>
							<div className="col-6 px-1">
								<PromotionCell
									template={props.template}
									productIndex={props.productIndex}
									productId={props.productId}
									name="retail_price"
									type="number"
									customClass="text-end"
									required={true}
									defaultValue={retail_price.value}
									country={props.countries[0]}
									setValue={props.setValue}
									rounding={2}
									disabled={props.disabled}
									itemId={props.i}
									min={0}
									step={0.01}
									setDataSaveStatus={props.setDataSaveStatus}
								/>
							</div>
							<div className="col-6 px-1">
								<PromotionCell
									template={props.template}
									productIndex={props.productIndex}
									productId={props.productId}
									name="base_price"
									type="number"
									customClass={
										'text-end ' +
										(base_price.approved
											? 'border-success bg-green-tint'
											: base_price.approved === false
												? 'border-danger bg-red-tint'
												: 'border-warning bg-yellow-tint')
									}
									required={true}
									defaultValue={base_price.value}
									country={props.countries[0]}
									setValue={props.setValue}
									disabled={true}
									itemId={props.i}
								/>
							</div>
						</>
					) : (
						<div className="col-12 px-1">
							<PromotionCell
								template={props.template}
								productIndex={props.productIndex}
								productId={props.productId}
								name="retail_price_status"
								type="text"
								customClass="text-center"
								required={true}
								defaultValue={retail_price.value}
								country={props.countries[0]}
								setValue={props.setValue}
								disabled={true}
								itemId={props.i}
								setDataSaveStatus={props.setDataSaveStatus}
							/>
						</div>
					)}
				</div>
			</div>
			<div className="col-1 d-flex pe-0 align-items-center justify-content-end">
				<button
					type="button"
					className="btn btn-icon color-gray"
					onClick={() => props.removeStockItem(props.i)}>
					<i className="bi bi-x-lg" />
				</button>
			</div>
		</div>
	);
}

import React, { Fragment } from 'react';
import Input from 'components/Forms/FormComponents/Input';
import { Product, PromotionColumn, Tab } from 'components/Forms/ProductsForm';
import { calculateValue, getBaseColumns } from 'utils/promotion';

export default function PromotionTemplateSummary(props: {
	data: Product[];
	template: string;
	srpTemplateTotal: number;
	basePriceTemplateTotal: number;
	retailPriceTemplateTotal: number;
	showTemplate: boolean;
	getValue: (tab_key: string, col_key: string, productIndex: number, c?: string) => string;
	tabs: Tab[];
	countries: string[];
	omitColumns: string[];
	setOmitColumns: (omitColumns: string[]) => void;
	listWeekFrom: string;
	listWeekTo: string;
	sumPrice: (product: Product, c?: string) => number;
}) {
	function calculateColumnSum(tab_key: string, col: PromotionColumn): number {
		if (col.key === 'GP%') {
			const tab = props.tabs.find((t) => t.key === tab_key);
			const marginCol = tab?.columns.find((c) => c.key === 'margin');
			const companySalesCol = tab?.columns.find((c) => c.key === 'company_sales');
			if (marginCol && companySalesCol) {
				return (
					(calculateColumnSum(tab_key, marginCol) / calculateColumnSum(tab_key, companySalesCol)) *
					100
				);
			}
			return 0;
		}

		if (props.countries.length) {
			return props.countries
				.map((c) =>
					props.data.reduce((sum, p, i) => {
						if (props.getValue(tab_key, col.key, i, c) !== '$calc$') {
							return (
								parseFloat(
									props.getValue(tab_key, col.key, i, c) &&
										props.getValue(tab_key, col.key, i, c) !== ''
										? props.getValue(tab_key, col.key, i, c)
										: '0'
								) + sum
							);
						} else {
							const baseColumn = getBaseColumns(
								props.countries,
								p,
								props.sumPrice,
								props.listWeekFrom,
								props.listWeekTo
							).find((bc) => bc.country === c);
							return (
								(baseColumn && col.definition
									? parseFloat(calculateValue(baseColumn, col.definition, col.decimal).toString())
									: 0) + sum
							);
						}
					}, 0)
				)
				.reduce((sum, c) => sum + c, 0);
		}

		return 0;
	}

	function showCol(type: string, key: string) {
		return (
			['number', 'percentage'].includes(type) &&
			!key.endsWith('week_from') &&
			!key.endsWith('week_to')
		);
	}

	return (
		<div className="row mt-3 mx-0 promotion-template-summary">
			<div className="promotion-sticky-left summary-section pe-2 pb-2">
				<div className="d-flex p-3 align-items-center">
					<div className="col-6 summary-template-name">
						{props.showTemplate && <h2>{props.template}</h2>}
					</div>
					<div className="col-2 px-1">
						<Input
							name={props.template + '.srp_template_total'}
							label={'SRP'}
							type="number"
							customClass="text-end"
							min={0}
							disabled={true}
							step={0.01}
							defaultValue={props.srpTemplateTotal.toFixed(2)}
							required={true}
						/>
					</div>

					<div className="col-2 px-1">
						<Input
							name={props.template + '.retail_price_template_total'}
							label={'Retail price'}
							type="number"
							customClass="text-end"
							min={0}
							step={0.01}
							required={true}
							disabled={true}
							defaultValue={props.retailPriceTemplateTotal.toFixed(2)}
						/>
					</div>

					<div className="col-2 px-1">
						{props.countries.length === 1 && (
							<Input
								name={props.template + '.base_price_template_total'}
								label={'Base price'}
								type="number"
								customClass="text-end"
								min={0}
								disabled={true}
								step={0.01}
								defaultValue={props.basePriceTemplateTotal.toFixed(2)}
								required={true}
							/>
						)}
					</div>
				</div>
			</div>
			<div className="summary-section ps-2">
				<div className="d-flex p-3 align-items-center">
					<div className="d-flex">
						{props.tabs.map((t, i) => (
							<Fragment key={i}>
								{t.columns.map((col, col_index) => (
									<Fragment key={col_index}>
										{!props.omitColumns.includes(col.key) && (
											<div className="position-relative">
												{showCol(col.type, col.key) && (
													<>
														<div className="col px-1">
															<Input
																label={col.name}
																name={props.template + '.sum.' + i + '.' + col.key}
																type={col.type}
																customClass="text-end"
																step={0.01}
																defaultValue={calculateColumnSum(t.key, col).toFixed(2)}
																required={false}
																disabled={true}
															/>
															{col.type === 'percentage' && <div>%</div>}
														</div>
														<button
															className="btn hide-column-btn"
															type="button"
															onClick={() => props.setOmitColumns([...props.omitColumns, col.key])}>
															<i className="bi-eye-slash" />
														</button>
													</>
												)}
											</div>
										)}
									</Fragment>
								))}
							</Fragment>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}

export function capitalizeFirstLetter(s: string): string {
	return s.charAt(0).toUpperCase() + s.slice(1);
}

export function humanizeString(s: string): string {
	if (s.startsWith('i_')) {
		s = s.slice(2);
	}
	return capitalizeFirstLetter(s.replace(/_/g, ' ').trim());
}

export function trimAtSign(s: string): string {
	return s.replace('@', '');
}

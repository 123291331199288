import { BaseColumns } from 'components/Forms/Promotion/PromotionProduct';
import { Product, StockItemCountry } from 'components/Forms/ProductsForm';

export function calculateValue(
	calculationBase: BaseColumns,
	definition: string,
	rounding?: number
) {
	try {
		let parsedDef = definition;

		Object.entries(calculationBase.columns).forEach(([key, value]) => {
			if (typeof value === 'string' && !isNumber(value, 2)) {
				parsedDef = parsedDef.replaceAll('[' + key + ']', '"' + value + '"');
			} else {
				parsedDef = parsedDef.replaceAll('[' + key + ']', value.toString());
			}
		});
		parsedDef = parsedDef.replaceAll('%', '');

		if (new RegExp(/[A-Za-z]/).exec(parsedDef) !== null) {
			let val = new Function(parsedDef)();
			if (typeof val === 'number' && rounding) {
				val = roundToDecimals(val, rounding);
			}
			return typeof val === 'number' && isNaN(val) ? 0 : val;
		}

		let val = eval(parsedDef);
		if (typeof val === 'number' && rounding) {
			val = roundToDecimals(val, rounding);
		}
		return isNaN(val) ? 0 : val;
	} catch (e) {
		console.error('Error while parsing definition:', definition, e);
		return 0;
	}
}

function roundToDecimals(val: number, decimals: number) {
	return (Math.round(val * Math.pow(10, decimals)) / Math.pow(10, decimals)).toFixed(decimals);
}

export function isNumber(val: string, decimals?: number) {
	let re;
	if (decimals) {
		re = new RegExp(`^\\d*\\.?\\d{0,${decimals}}$`);
	} else {
		re = new RegExp('^\\d*$');
	}
	return val === '' || re.test(val);
}

export function getBaseColumns(
	countries: string[],
	product: Product,
	sumPrice: (product: Product, c?: string) => number,
	listWeekFrom: string,
	listWeekTo: string
) {
	return countries.map((c, ci) => {
		if (product.countries[ci] !== undefined) {
			const dsp = (product.countries[ci] as any).tab_retail[0].ds_price_retail;
			return {
				country: c,
				columns: {
					COUNTRY: c,
					pcs_per_party: (product.countries[ci] as any).tab_retail[0].pcs_per_party ?? 0,
					party_per_period: (product.countries[ci] as any).tab_retail[0].party_per_period ?? 0,
					total_quantity: (product.countries[ci] as any).tab_retail[0].total_quantity ?? 0,
					SRP: sumPrice(product) ?? 0,
					CSW: parseInt(listWeekFrom),
					CEW: parseInt(listWeekTo),
					DSP: dsp == '' ? 0 : dsp ?? 0,
					SP: (product.countries[ci] as any).tab_sample[0].sample_price ?? 0,
					RP: (product.countries[ci] as any).tab_retail[0].retail_price ?? 0,
					BP: product.items
						.map((item) => {
							const itemCountry: StockItemCountry | undefined = item.countries?.find(
								(pc) => pc.i_country === c
							);
							return (
								parseInt(item.quantity.toString()) *
								parseFloat((itemCountry?.base_price ?? 0).toString())
							);
						})
						.reduce((acc, item) => acc + item, 0),
					RBP: product.items
						.map((item) => {
							const itemCountry: StockItemCountry | undefined = item.countries?.find(
								(pc) => pc.i_country === c
							);
							return (
								parseInt(item.quantity.toString()) *
								parseFloat((itemCountry?.retail_price ?? 0).toString())
							);
						})
						.reduce((acc, item) => acc + item, 0),
					COP: (product.countries[ci] as any).tab_contribution[0].co_price ?? 0,
					ESP: (product.countries[ci] as any).tab_retail[0].retail_price ?? 0,
					VAT: (product.countries[ci] as any).tab_retail[0].vat ?? 0,
					DSPR: (product.countries[ci] as any).tab_retail[0].ds_price_retail ?? 0,
					DSPS: (product.countries[ci] as any).tab_sample[0].ds_price_sample ?? 0,
					DSPH: (product.countries[ci] as any).tab_half_of_hostess[0].ds_price_hoh ?? 0,
					DSPST: (product.countries[ci] as any).tab_star[0].ds_price_st ?? 0,
					DSPCO: (product.countries[ci] as any).tab_contribution[0].ds_price_co ?? 0,
					DSPES: (product.countries[ci] as any).tab_eshop[0].ds_price_eshop ?? 0
				}
			};
		} else {
			return {
				country: c,
				columns: {
					COUNTRY: c,
					pcs_per_party: 0,
					party_per_period: 0,
					total_quantity: 0,
					SRP: 0,
					CSW: 0,
					CEW: 0,
					DSP: 0,
					SP: 0,
					RP: 0,
					BP: 0,
					RBP: 0,
					COP: 0,
					ESP: 0,
					VAT: 0,
					DSPR: 0,
					DSPS: 0,
					DSPH: 0,
					DSPST: 0,
					DSPCO: 0,
					DSPES: 0
				}
			};
		}
	});
}

import React, { useRef, useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FieldError, FieldValues, Path, UseFormRegister } from 'react-hook-form';

export default function Input<T extends FieldValues>(props: {
	inputId?: string;
	name?: string;
	customClass?: string;
	label?: string;
	register?: UseFormRegister<T>;
	error?: FieldError;
	defaultValue?: string;
	required: boolean;
	disabled?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
	customError?: string;
	options: Map<string, string>;
}) {
	const inputId = props.inputId ?? props.name;

	const labelRef = useRef<HTMLLabelElement>(null);
	const [labelOverflowing, setLabelOverflowing] = useState(false);

	useEffect(() => {
		const label = labelRef.current;
		if (label) {
			const isOverflow = label.scrollWidth > label.clientWidth;
			setLabelOverflowing(isOverflow);
		}
	}, []);

	if (props.register === undefined) {
		return (
			<>
				<div className="form-floating">
					<select
						disabled={props.disabled}
						id={inputId}
						value={props.defaultValue}
						className={
							'form-control ' +
							(props.customClass ?? '') +
							(props.error || props.customError ? ' is-invalid' : '')
						}
						onInput={props.onChange}>
						{Array.from(props.options).map(([key, value]) => (
							<option key={key} value={key}>
								{value}
							</option>
						))}
					</select>
					<label htmlFor={inputId}>{props.label}</label>
					{labelOverflowing && (
						<OverlayTrigger overlay={<Tooltip id={inputId + '-tooltip'}>{props.label}</Tooltip>}>
							<a href="#" className="tooltip-trigger" />
						</OverlayTrigger>
					)}
				</div>
			</>
		);
	}

	return (
		<>
			<div className="form-floating">
				<select
					{...props.register(props.name as Path<T>, {
						required: props.required
					})}
					disabled={props.disabled}
					id={inputId}
					defaultValue={props.defaultValue}
					className={
						'form-control ' +
						(props.customClass ?? '') +
						(props.error || props.customError ? ' is-invalid' : '')
					}
					onInput={props.onChange}>
					{Array.from(props.options).map(([key, value]) => (
						<option key={key} value={key}>
							{value}
						</option>
					))}
				</select>
				<label htmlFor={inputId}>{props.label}</label>
			</div>
		</>
	);
}

import axios, { AxiosError } from 'axios';
import { LoginResponse } from 'utils/auth';
import { NavigateFunction } from 'react-router-dom';
import { authItemKey, unsetUser } from 'state/userSlice';
import { Dispatch, UnknownAction } from '@reduxjs/toolkit';
import { addNotification, removeNotification } from 'state/notificationSlice';

axios.defaults.baseURL = process.env.REACT_APP_NODE_API;
axios.defaults.headers.post['Content-Type'] = 'application/json';

export function getAuthItem(): LoginResponse | null {
	const item = localStorage.getItem(authItemKey);
	return item ? (JSON.parse(item) as LoginResponse) : null;
}

export function isUserLoggedIn(): boolean {
	return !!getAuthItem();
}

export function setAuthItem(token: LoginResponse) {
	localStorage.setItem(authItemKey, JSON.stringify(token));
}

function clearLocalStorage() {
	localStorage.clear();
}

export function logOut(dispatch: Dispatch<UnknownAction>) {
	clearLocalStorage();
	dispatch(unsetUser());
}

function refreshAuthToken(navigate?: NavigateFunction): Promise<void> {
	const authItem = getAuthItem();

	if (authItem) {
		if (
			new Date(authItem.expires_at) < new Date() &&
			new Date(authItem.rt_expires_at) > new Date()
		) {
			const headers = { Authorization: `Bearer ${authItem.token}` };

			return axios({
				headers,
				method: 'POST',
				url: '/refresh-token',
				data: { refresh_token: authItem.refresh_token }
			})
				.then((response) => {
					setAuthItem(response.data as LoginResponse);
				})
				.catch((error) => {
					if (error instanceof AxiosError && navigate) {
						navigate('/login');
					}
				});
		}
	}
	return new Promise((resolve) => resolve());
}

export function sendHttpRequest(
	method: string,
	url: string,
	data: object | null,
	navigate?: NavigateFunction,
	dispatch?: Dispatch<UnknownAction>
) {
	return refreshAuthToken(navigate)
		.then(() => {
			const authItem = getAuthItem();

			const headers: { Authorization?: string; 'Content-Type'?: string } = authItem
				? { Authorization: `Bearer ${authItem.token}` }
				: {};

			if (data && Object.values(data).some((value) => value instanceof File)) {
				headers['Content-Type'] = 'multipart/form-data';
			} else if (data === null) {
				headers['Content-Type'] = undefined;
			}

			return axios({
				headers: headers,
				method: method,
				url: url,
				data: data
			});
		})
		.then((response) => {
			if (response.data.length) {
				for (const block of response.data) {
					if (block[0] !== undefined && block[0].t_l !== undefined) {
						block
							.filter((log: any) => ['I', 'E', 'W', 'S'].includes(log.t_l))
							.forEach((log: any) => {
								let severity = 'info' as 'info' | 'success' | 'warning' | 'error';
								switch (log.t_l) {
									case 'E':
										severity = 'error';
										break;
									case 'W':
										severity = 'warning';
										break;
									case 'S':
										severity = 'success';
										break;
									default:
										break;
								}
								dispatch?.(
									addNotification({ id: log.i_l, message: log.n_l, severity: severity, rq: log.rq })
								);
								if (severity !== 'error') {
									setTimeout(() => {
										dispatch?.(removeNotification(log.i_l));
									}, 5000);
								}
							});

						break;
					}
				}
			}
			return response;
		});
}

export function executeAction(
	data: object,
	navigate: NavigateFunction,
	dispatch: Dispatch<UnknownAction>
) {
	return sendHttpRequest('POST', '/execute', data, navigate, dispatch);
}

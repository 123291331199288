import React, { Fragment, useEffect, useState } from 'react';
import { sendHttpRequest } from 'utils/httpRequestManager';
import { useNavigate } from 'react-router-dom';
import { humanizeString } from 'utils/string';
import { AppComponent } from 'utils/components';
import { useDispatch, useSelector } from 'react-redux';
import { setStockItems } from 'state/stockItemSlice';
import PromotionTemplate from 'components/Forms/Promotion/PromotionTemplate';
import { Dropdown, Modal } from 'react-bootstrap';
import Loading from 'components/parts/Loading';
import Select from 'components/Forms/FormComponents/Select';
import Input from 'components/Forms/FormComponents/Input';
import { RootState } from 'state/store';
import Alert from 'components/parts/Alert';
import { useSendRequest } from 'sockets/default';
import { clearResponse } from 'state/socketSlice';
import ProgressModal from 'components/parts/ProgressModal';

export type Product = {
	i_product: number | null;
	action: string | null;
	i_template: string;
	i_usg: string;
	GP_group: string;
	main_cluster: string;
	channel: string;
	items: StockItem[];
	product_price_code: string;
	product_short_code: string | null;
	retail_price: number | string;
	valid_from: string;
	valid_to: string;
	id: number;
	countries: ProductCountry[];
};

export type ProductCountry = {
	i_country: string;
	product_name: string;
	tab_retail: object[];
	variable: {
		[key: string]: string | number;
	};
};

export type StockItemCountry = {
	approved: boolean | null;
	base_price: number | string | null;
	retail_price: number | string | null;
	i_country: string;
	product_name: string | null;
};

export type StockItem = {
	countries: StockItemCountry[];
	c_item: string;
	i_item: string;
	n_item: string;
	quantity: number | string;
	srp: number | string;
};

export type PromotionColumn = {
	name: string;
	key: string;
	decimal?: number;
	currency?: string;
	type: 'text' | 'number' | 'datetime-local' | 'checkbox' | 'percentage';
	definition?: string;
	disabled: boolean;
};

export type Tab = {
	name: string;
	key: string;
	columns: PromotionColumn[];
};

export type PromotionStructure = {
	i_template: string;
	i_usg: string;
	GP_group: string;
	general_inputs: object[];
	product_inputs: object[];
	item_inputs: object[];
	tabs: Tab[];
	countries: Map<string, { i_country: string; tabs: Tab[] }>;
};

export type PromotionViewLayout = {
	i_prop: number;
	name: string;
	omit_columns: string[];
};

export type Promotion = {
	i_list: number;
	c_list: string;
	valid_from: string;
	valid_to: string;
	week_from: string;
	week_to: string;
};

export type DataSaveStatus = 'Data saved' | 'Saving' | 'Save data';

export default function ProductsForm(props: { component: AppComponent }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [data, setData] = useState([] as Product[]);
	const [list, setList] = useState(null as null | Promotion);
	const [structures, setStructures] = useState([] as PromotionStructure[]);
	const [loading, setLoading] = useState(false);

	const [layouts, setLayouts] = useState([] as PromotionViewLayout[]);
	const [currentLayout, setCurrentLayout] = useState(null as PromotionViewLayout | null);

	const [templates, setTemplates] = useState([] as string[]);
	const [allTemplates, setAllTemplates] = useState([] as { key: string; value: string }[]);
	const [usages, setUsages] = useState([] as string[]);
	const [gpCategories, setGpCategories] = useState([] as string[]);
	const [clusters, setClusters] = useState([] as string[]);
	const [channels, setChannels] = useState([] as string[]);
	const [omitColumns, setOmitColumns] = useState([] as string[]);

	const [cList, setCList] = useState('');
	const [listWeekFrom, setListWeekFrom] = useState('');
	const [listWeekTo, setListWeekTo] = useState('');
	const [listInfoSaved, setListInfoSaved] = useState(true);
	const [dataSaveStatus, setDataSaveStatus] = useState('Data saved' as DataSaveStatus);

	const allCountries = useSelector((state: RootState) => state.user.allCountries);

	const jdeRequestName = 'product_export_jde';
	const responseData = useSelector((state: RootState) => state.socket.responses).find(
		(r) => r.request === jdeRequestName
	);

	const [countries, setCountries] = useState(allCountries as string[]);

	const i_business = JSON.parse(props.component.variables ?? '{}').i_business;

	const [showModal, setShowModal] = useState(false);

	const sendRequest = useSendRequest();

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		if (!urlParams.has('i_list') && window.location.pathname.includes('create')) {
			sendHttpRequest('POST', '/promotion', { i_business: i_business }, navigate, dispatch).then(
				(response) => {
					navigate('/list_detail?i_list=' + response.data[0][0].i_list);
				}
			);
		}
	}, [dispatch, i_business, navigate]);

	function toggleColumnVisibility(col_key: string) {
		if (omitColumns.includes(col_key)) {
			changeOmittedColumns(omitColumns.filter((c) => c !== col_key));
		} else {
			changeOmittedColumns([...omitColumns, col_key]);
		}
	}

	function hideAllColumns() {
		changeOmittedColumns(
			omitColumns.concat(
				structures[0]?.tabs.flatMap((t: { columns: { key: string }[] }) =>
					t.columns.map((c) => c.key)
				)
			)
		);
	}

	function showAllColumns() {
		changeOmittedColumns([]);
	}

	useEffect(() => {
		sendHttpRequest(
			'GET',
			'/promotion-layout',
			{ i_business: i_business },
			navigate,
			dispatch
		).then((response) => {
			setLayouts(
				response.data[0].map(
					(row: { val: string; i_prop: number }) =>
						({ ...JSON.parse(row.val), i_prop: row.i_prop }) as PromotionViewLayout
				)
			);
		});
	}, [dispatch, navigate, i_business]);

	function saveLayout() {
		const newLayoutName = prompt('Enter layout name');
		if (!newLayoutName) {
			return;
		}

		const layout = {
			name: newLayoutName,
			omit_columns: omitColumns
		};

		layouts
			.filter((l) => l.name === newLayoutName)
			.forEach((l) =>
				sendHttpRequest(
					'DELETE',
					'/promotion-layout',
					{ i_prop: l.i_prop, i_business: i_business },
					navigate,
					dispatch
				)
			);

		sendHttpRequest(
			'POST',
			'/promotion-layout',
			{ i_business: i_business, layout: layout },
			navigate,
			dispatch
		).then((response) => {
			const data: { i_prop: number; val: string } = response.data[0][0];
			const newLayout: PromotionViewLayout = {
				...JSON.parse(data.val),
				i_prop: data.i_prop
			};

			setLayouts([...layouts.filter((l) => l.name !== newLayoutName), newLayout]);

			setCurrentLayout(newLayout);
		});
	}

	function loadLayout(layout: PromotionViewLayout) {
		setOmitColumns(layout.omit_columns);
		setCurrentLayout(layout);
	}
	function deleteLayout(layout: PromotionViewLayout) {
		const confirmation = confirm(
			'Are you sure you want to delete the layout "' + layout.name + '"?'
		);
		if (!confirmation) {
			return;
		}
		sendHttpRequest(
			'DELETE',
			'/promotion-layout',
			{ i_prop: layout.i_prop, i_business: i_business },
			navigate,
			dispatch
		).then(() => {
			if (currentLayout == layout) {
				setCurrentLayout(null);
				setOmitColumns([]);
			}
			setLayouts(layouts.filter((l) => l.i_prop !== layout.i_prop));
		});
	}

	function changeOmittedColumns(c: string[]) {
		setOmitColumns(c);
		setCurrentLayout(null);
	}

	function addProduct(template: string) {
		data.push({
			i_product: null,
			action: null,
			i_template: template,
			i_usg: '',
			GP_group: '',
			main_cluster: '',
			channel: '',
			items: [
				{
					c_item: '',
					i_item: '',
					n_item: '',
					quantity: 1,
					srp: '0.00',
					countries: []
				}
			],
			product_price_code: '',
			product_short_code: null,
			retail_price: '',
			id: data.length,
			countries: [],
			valid_from: '',
			valid_to: ''
		});
		setData([...data]);
	}

	function removeProduct(productId: number) {
		const product = data.find((row) => row.id === productId);

		if (product) {
			product.action = 'delete';
			sendHttpRequest(
				'POST',
				'/promotion/' + (urlParams.get('i_list') ?? '1') + '/save',
				{ json: JSON.stringify(product), i_business: i_business },
				navigate,
				dispatch
			).then(() => {
				const d = data.filter((row) => row.id !== productId);
				setData([...d]);

				if (
					Array.from(new Set(d.map((row: { i_template: string }) => row.i_template))).length <
					templates.length
				) {
					setTemplates(templates.filter((t) => t !== product.i_template));
				}
			});
		}
	}

	const [showTemplateModal, setShowTemplateModal] = useState(false);

	const handleCloseTemplateModal = () => setShowTemplateModal(false);
	const handleOpenTemplateModal = () => setShowTemplateModal(true);

	const [newTemplateSelect, setNewTemplateSelect] = useState(undefined as string | undefined);

	function addTemplate() {
		if (newTemplateSelect === undefined) {
			return;
		}

		templates.push(newTemplateSelect);
		setNewTemplateSelect(undefined);
		setTemplates([...templates]);
		setShowTemplateModal(false);
		addProduct(newTemplateSelect);
	}

	function convertToColumnDefinitions(data: object): PromotionColumn[] {
		return Object.entries(data).map(([colKey, colValue]) => {
			colValue = colValue[0];
			let type: 'text' | 'number' | 'datetime-local' | 'checkbox' | 'percentage' = 'number';
			switch (colValue.type) {
				case 'datetime':
					type = 'datetime-local';
					break;
				case 'character':
					type = 'text';
					break;
				case 'bit':
					type = 'checkbox';
					break;
				case 'percentage':
					type = 'percentage';
					break;
			}

			return {
				name: humanizeString(colKey),
				key: colKey,
				decimal: colValue.decimal,
				currency: colValue.currency,
				type: type,
				definition: colValue.definition,
				disabled: colValue.blocked ?? false
			};
		});
	}

	useEffect(() => {
		sendHttpRequest(
			'POST',
			'/promotion/structure',
			{ i_business: i_business },
			navigate,
			dispatch
		).then((response) => {
			const templates: { key: string; value: string }[] = Array.from(
				new Set(
					response.data[0].map((row: { i_template: string; n_template: string }) => {
						return { key: row.i_template, value: row.n_template };
					})
				)
			);
			setAllTemplates(templates);

			const structures = response.data[0].map((row: { json: string; i_template: string }) => {
				const data = JSON.parse(row.json)[0];
				const countries: Map<string, { i_country: string; tabs: Tab[] }> = new Map();
				data.countries
					.map((c: { i_country: string; tab_retail: object }) => {
						return {
							i_country: c.i_country,
							tabs: Object.entries(c)
								.filter(([key]) => key !== 'i_country')
								.map(([key, value]) => {
									return {
										name: humanizeString(key.substring(4)),
										key: key,
										columns: convertToColumnDefinitions((value as object[])[0])
									} as Tab;
								})
						};
					})
					.forEach((c: { i_country: string; tabs: Tab[] }) => {
						countries.set(c.i_country, c);
					});
				return {
					i_template: row.i_template,
					general_inputs: convertToColumnDefinitions(data.general[0]),
					product_inputs: convertToColumnDefinitions(data.product[0]),
					item_inputs: convertToColumnDefinitions(data.items[0]),
					tabs: Object.entries(data.countries[0])
						.filter(([key]) => key !== 'i_country')
						.map(([key, value]) => {
							return {
								name: humanizeString(key.substring(4)),
								key: key,
								columns: convertToColumnDefinitions((value as object[])[0])
							} as Tab;
						}),
					countries: countries
				} as PromotionStructure;
			});
			setStructures(structures);
		});
	}, [navigate, i_business, dispatch]);

	const [viewLevel, setViewLevel] = useState(
		'stock_items' as 'stock_items' | 'products' | 'summary'
	);
	const viewLevelLabels = {
		stock_items: 'Stock items',
		products: 'Products',
		summary: 'Summary'
	};

	useEffect(() => {
		if (window.location.search.length > 0) {
			setLoading(true);
			const urlParams = new URLSearchParams(window.location.search);

			sendHttpRequest(
				'GET',
				'/promotion/' + (urlParams.get('i_list') ?? '1'),
				null,
				navigate,
				dispatch
			).then((response) => {
				setData(
					response.data[0][0]
						.map((row: { prod_json: string }) => JSON.parse(row.prod_json ?? '{}'))
						.filter((row: Product) => row.i_template !== undefined)
						.map((row: Product) => {
							if (row.retail_price !== undefined && row.retail_price !== null) {
								row.retail_price = (row.retail_price as number).toFixed(2);
							} else {
								row.retail_price = '';
							}
							if (row.items === undefined || row.items === null) {
								row.items = [];
							}
							for (const item of row.items) {
								if (item.srp !== undefined) {
									item.srp = ((item.srp ?? 0) as number).toFixed(2);
								}
							}
							return row;
						})
						.map((row: object, i: number) => {
							return {
								...row,
								id: i
							};
						})
				);
				setTemplates(
					Array.from(
						new Set(response.data[0][0].map((row: { i_template: string }) => row.i_template))
					)
				);
				setUsages(
					Array.from(
						new Set(
							response.data[0][0]
								.map((row: { prod_json: string }) => JSON.parse(row.prod_json ?? '{}'))
								.map((row: { i_usg: string }) => row.i_usg ?? null)
						)
					)
				);
				setGpCategories(
					Array.from(
						new Set(
							response.data[0][0]
								.map((row: { prod_json: string }) => JSON.parse(row.prod_json ?? '{}'))
								.map((row: { GP_group: string }) => row.GP_group ?? null)
						)
					)
				);
				setClusters(
					Array.from(
						new Set(
							response.data[0][0]
								.map((row: { prod_json: string }) => JSON.parse(row.prod_json ?? '{}'))
								.map((row: { main_cluster: string }) => row.main_cluster ?? null)
						)
					)
				);
				setChannels(
					Array.from(
						new Set(
							response.data[0][0]
								.map((row: { prod_json: string }) => JSON.parse(row.prod_json ?? '{}'))
								.map((row: { channel: string }) => row.channel ?? null)
						)
					)
				);

				const list = response.data[1][0][0];
				setList(list);
				setCList(list.c_list ?? '');
				if (list.week_from != null) {
					setListWeekFrom(list.week_from);
				}
				if (list.week_to != null) {
					setListWeekTo(list.week_to);
				}
				setLoading(false);
			});
		} else {
			setData([]);
		}
	}, [dispatch, navigate, viewLevel]);

	useEffect(() => {
		sendHttpRequest('POST', '/cartons', {}, navigate, dispatch).then((response) => {
			dispatch(setStockItems(response.data[0]));
		});
	}, [dispatch, navigate]);

	const [grouping, setGrouping] = useState('template' as keyof typeof groupingLabels);
	const groupingLabels = {
		channel: 'Channel',
		cluster: 'Main Cluster',
		gp: 'Sub-Cluster',
		usage: 'Usage',
		template: 'Template'
	};

	function submitListInfo() {
		sendHttpRequest(
			'POST',
			'/promotion/' + (list?.i_list ?? '1'),
			{ c_list: cList, week_from: listWeekFrom, week_to: listWeekTo },
			navigate,
			dispatch
		).then(() => {
			setListInfoSaved(true);
			console.info('saved');
		});
	}

	const urlParams = new URLSearchParams(window.location.search);

	function onWeekChange(value: string, setFunc: (val: string) => void) {
		if (!(value.length > 6)) {
			setFunc(value);
			setListInfoSaved(false);
		}
	}

	if (!urlParams.has('i_list')) {
		return <></>;
	}

	function sendToJDE() {
		setShowModal(true);
		sendRequest(jdeRequestName, { i_list: list?.i_list });
	}

	const jdeRequestStatus = responseData
		? (responseData?.response as { status: string }).status
		: null;

	const closeJDEModal = () => {
		setShowModal(false);
		dispatch(clearResponse(jdeRequestName));
	};

	return (
		<div>
			{loading && (
				<div className="row justify-content-center pt-5 mt-5">
					<Loading />
				</div>
			)}
			{!loading && (
				<form className="create-product-form">
					<div className="template-wrapper create-product-form-header flex-wrap">
						<div className="template-name">&nbsp;</div>
						<div>
							<div className="d-flex w-100 mb-3">
								<div className="col-12 ms-3 d-flex list-info-wrapper justify-content-between align-items-center">
									<div className="d-flex">
										<Input
											name="c_list"
											type="text"
											defaultValue={cList}
											required={true}
											label="c_list"
											onChange={(e) => {
												setCList(e.target.value);
												setListInfoSaved(false);
											}}
										/>
										<Input
											type="number"
											maxLength={6}
											required={true}
											defaultValue={listWeekFrom}
											label="Week from"
											name="week_from"
											onChange={(e) => onWeekChange(e.target.value, setListWeekFrom)}
											customClass="text-end"
										/>
										<Input
											type="number"
											maxLength={6}
											required={true}
											defaultValue={listWeekTo}
											label="Week to"
											name="week_to"
											onChange={(e) => onWeekChange(e.target.value, setListWeekTo)}
											customClass="text-end"
										/>
										<button
											type="button"
											className="btn btn-primary"
											onClick={submitListInfo}
											disabled={
												listInfoSaved || cList === '' || listWeekFrom === '' || listWeekTo === ''
											}>
											Save
										</button>
									</div>
									<div className="pe-5">
										<button
											type="button"
											className="btn btn-primary me-3 data-save"
											onClick={(e) => {
												e.preventDefault();
												sendToJDE();
											}}>
											<span>Send to JDE</span>
										</button>
										<button
											className="btn btn-primary data-save"
											disabled={dataSaveStatus !== 'Save data'}
											onClick={(e) => e.preventDefault()}>
											{dataSaveStatus === 'Saving' && (
												<span
													className="me-2 spinner-border spinner-border-sm"
													role="status"
													aria-hidden="true"></span>
											)}
											<span>{dataSaveStatus}</span>
										</button>
									</div>
								</div>
							</div>
							<div className="d-flex w-100">
								<div className="col-12 ms-3 d-flex justify-content-between">
									<div className="d-flex text-nowrap">
										<div className="me-3">
											<Dropdown align="end" className="grouping-dropdown">
												<Dropdown.Toggle variant="outline-primary" id="dropdown-layout">
													<span>{groupingLabels[grouping]}</span>
													<i className="ms-2 bi-chevron-down"></i>
												</Dropdown.Toggle>

												<Dropdown.Menu>
													{Object.entries(groupingLabels).map(([key, value]) => (
														<Dropdown.Item
															key={key}
															onClick={() => setGrouping(key as keyof typeof groupingLabels)}>
															{value}
														</Dropdown.Item>
													))}
												</Dropdown.Menu>
											</Dropdown>
										</div>
										<div className="me-3">
											<Dropdown align="end" className="view-level-dropdown">
												<Dropdown.Toggle variant="outline-primary" id="dropdown-layout">
													<span>{viewLevelLabels[viewLevel]}</span>
													<i className="ms-2 bi-chevron-down"></i>
												</Dropdown.Toggle>

												<Dropdown.Menu>
													<Dropdown.Item onClick={() => setViewLevel('summary')}>
														GP Calculator
													</Dropdown.Item>
													<Dropdown.Item onClick={() => setViewLevel('products')}>
														Products
													</Dropdown.Item>
													<Dropdown.Item onClick={() => setViewLevel('stock_items')}>
														Stock items
													</Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</div>
										<div className="me-3">
											<Dropdown align="end" className="countries-dropdown">
												<Dropdown.Toggle variant="outline-primary">
													<span>{countries.length === 1 ? countries[0] : 'All countries'}</span>
													<i className="ms-2 bi-chevron-down"></i>
												</Dropdown.Toggle>

												<Dropdown.Menu>
													<Dropdown.Item
														onClick={() => {
															setCountries(allCountries);
														}}>
														Show all countries
													</Dropdown.Item>

													<hr />

													{allCountries.map((country) => (
														<Dropdown.Item key={country} onClick={() => setCountries([country])}>
															{country}
														</Dropdown.Item>
													))}
												</Dropdown.Menu>
											</Dropdown>
										</div>
									</div>
									<div className="pe-5 d-flex">
										<div>
											<Dropdown align="end">
												<Dropdown.Toggle variant="outline-primary" id="dropdown-layout">
													<span>{currentLayout?.name ?? 'Custom layout'}</span>
													<i className="ms-2 bi-chevron-down"></i>
												</Dropdown.Toggle>

												<Dropdown.Menu>
													<Dropdown.Item
														onClick={(e) => {
															e.stopPropagation();
															saveLayout();
														}}>
														Save current layout
													</Dropdown.Item>

													<hr />

													{layouts.map((layout, i) => (
														<div key={i} className="btn-group d-flex">
															<Dropdown.Item
																className="layout-selector"
																onClick={() => {
																	loadLayout(layout);
																}}>
																{layout.name}
															</Dropdown.Item>
															<Dropdown.Item
																className="layout-trash text-end"
																onClick={() => {
																	deleteLayout(layout);
																}}>
																<i className="bi bi-x-lg" />
															</Dropdown.Item>
														</div>
													))}
												</Dropdown.Menu>
											</Dropdown>
										</div>
										<div className="ms-3">
											<Dropdown>
												<Dropdown.Toggle variant="outline-primary" id="dropdown-omit-columns">
													<span>Columns visibility</span>
													<i className="ms-2 bi-chevron-down"></i>
												</Dropdown.Toggle>

												<Dropdown.Menu>
													<Dropdown.Item
														onClick={(e) => {
															e.stopPropagation();
															hideAllColumns();
														}}>
														Hide all columns
													</Dropdown.Item>
													<Dropdown.Item
														onClick={(e) => {
															e.stopPropagation();
															showAllColumns();
														}}>
														Show all columns
													</Dropdown.Item>

													<hr />

													{(structures[0]?.tabs ?? []).map((t, i) => (
														<Fragment key={i}>
															{t.columns.map((col, col_index) => (
																<Fragment key={col_index}>
																	<Dropdown.Item
																		onClick={(e) => {
																			e.stopPropagation();
																			toggleColumnVisibility(col.key);
																		}}
																		className={omitColumns.includes(col.key) ? 'color-gray' : ''}>
																		{omitColumns.includes(col.key) ? (
																			<i className="bi-eye-slash me-3" />
																		) : (
																			<i className="bi-eye me-3" />
																		)}
																		{col.name}
																	</Dropdown.Item>
																</Fragment>
															))}
														</Fragment>
													))}
												</Dropdown.Menu>
											</Dropdown>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{(cList === '' || listWeekFrom === '' || listWeekTo === '' || !listInfoSaved) && (
						<Alert
							message="You need to fill out and save the promotion details before continuing!"
							type="danger"
							customClass="promotion-saving-alert"
						/>
					)}
					<div
						className={
							cList === '' || listWeekFrom === '' || listWeekTo === '' || !listInfoSaved
								? 'faded'
								: ' '
						}>
						{grouping == 'template' && (
							<div className="row">
								{templates.map((template, i) => (
									<PromotionTemplate
										key={i}
										template={template}
										tabs={structures[0]?.tabs}
										structures={structures}
										viewLevel={viewLevel}
										products={data.filter((row) => row.i_template === template)}
										index={i}
										addProduct={addProduct}
										i_business={i_business}
										countries={countries}
										omitColumns={omitColumns}
										setOmitColumns={changeOmittedColumns}
										grouping={grouping}
										setDataSaveStatus={setDataSaveStatus}
										removeProduct={removeProduct}
										listWeekFrom={listWeekFrom}
										listWeekTo={listWeekTo}
									/>
								))}
								{viewLevel !== 'summary' && (
									<div className="add-template-btn-wrapper">
										<button
											onClick={handleOpenTemplateModal}
											type="button"
											className="btn btn-outline-primary">
											<i className="bi bi-plus-lg fs-3"></i>
										</button>
									</div>
								)}
							</div>
						)}
						{grouping == 'usage' && (
							<div className="row">
								{usages.map((usg, i) => (
									<PromotionTemplate
										key={i}
										template={usg}
										tabs={structures[0].tabs}
										structures={structures}
										viewLevel={viewLevel}
										products={data.filter(
											(row) => row.i_usg === usg || (row.i_usg === undefined && usg === null)
										)}
										index={i}
										addProduct={addProduct}
										i_business={i_business}
										countries={countries}
										omitColumns={omitColumns}
										setOmitColumns={changeOmittedColumns}
										grouping={grouping}
										setDataSaveStatus={setDataSaveStatus}
										removeProduct={removeProduct}
										listWeekFrom={listWeekFrom}
										listWeekTo={listWeekTo}
									/>
								))}
							</div>
						)}
						{grouping == 'gp' && (
							<div className="row">
								{gpCategories.map((gpc, i) => (
									<PromotionTemplate
										key={i}
										template={gpc}
										tabs={structures[0].tabs}
										structures={structures}
										viewLevel={viewLevel}
										products={data.filter(
											(row) => row.GP_group === gpc || (row.GP_group === undefined && gpc === null)
										)}
										index={i}
										addProduct={addProduct}
										i_business={i_business}
										countries={countries}
										omitColumns={omitColumns}
										setOmitColumns={changeOmittedColumns}
										grouping={grouping}
										setDataSaveStatus={setDataSaveStatus}
										removeProduct={removeProduct}
										listWeekFrom={listWeekFrom}
										listWeekTo={listWeekTo}
									/>
								))}
							</div>
						)}
						{grouping == 'cluster' && (
							<div className="row">
								{clusters.map((clus, i) => (
									<PromotionTemplate
										key={i}
										template={clus}
										tabs={structures[0].tabs}
										structures={structures}
										viewLevel={viewLevel}
										products={data.filter(
											(row) =>
												row.main_cluster === clus ||
												(row.main_cluster === undefined && clus === null)
										)}
										index={i}
										addProduct={addProduct}
										i_business={i_business}
										countries={countries}
										omitColumns={omitColumns}
										setOmitColumns={changeOmittedColumns}
										grouping={grouping}
										setDataSaveStatus={setDataSaveStatus}
										removeProduct={removeProduct}
										listWeekFrom={listWeekFrom}
										listWeekTo={listWeekTo}
									/>
								))}
							</div>
						)}
						{grouping == 'channel' && (
							<div className="row">
								{channels.map((chan, i) => (
									<PromotionTemplate
										key={i}
										template={chan}
										tabs={structures[0].tabs}
										structures={structures}
										viewLevel={viewLevel}
										products={data.filter(
											(row) => row.channel === chan || (row.channel === undefined && chan === null)
										)}
										index={i}
										addProduct={addProduct}
										i_business={i_business}
										countries={countries}
										omitColumns={omitColumns}
										setOmitColumns={changeOmittedColumns}
										grouping={grouping}
										setDataSaveStatus={setDataSaveStatus}
										removeProduct={removeProduct}
										listWeekFrom={listWeekFrom}
										listWeekTo={listWeekTo}
									/>
								))}
							</div>
						)}
					</div>
				</form>
			)}

			<Modal show={showTemplateModal} onHide={handleCloseTemplateModal}>
				<Modal.Header closeButton>
					<Modal.Title>Add template</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					Choose a template you want to create a new product for.
					<Select
						name="template"
						label="Template"
						defaultValue={newTemplateSelect}
						onChange={(e) => setNewTemplateSelect(e.target.value)}
						required={true}
						options={
							new Map(
								allTemplates
									.filter((t) => !templates.includes(t.key))
									.map((t) => [t.key, t.key + ' - ' + t.value])
							)
						}
					/>
					<button type="button" onClick={addTemplate} className="btn btn-primary mt-3">
						Add template
					</button>
				</Modal.Body>
			</Modal>
			<ProgressModal status={jdeRequestStatus} showModal={showModal} closeModal={closeJDEModal} />
		</div>
	);
}

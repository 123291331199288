import React, { useState, useEffect } from 'react';
import NonFloatingInput from 'components/Forms/FormComponents/NonFloatingInput';
import { BaseColumns } from 'components/Forms/Promotion/PromotionProduct';
import { calculateValue } from 'utils/promotion';
import Checkbox from 'components/Forms/FormComponents/Checkbox';
import { DataSaveStatus } from 'components/Forms/ProductsForm';
import { RootState } from 'state/store';
import { useSelector } from 'react-redux';

export default function PromotionCell(props: {
	template: string;
	productIndex: number;
	productId: number;
	country?: string;
	name: string;
	type: string;
	required: boolean;
	itemId?: number;
	tabName?: string;
	customClass?: string;
	min?: number;
	defaultValue?: string | number;
	getValue?: (tab_key: string | null, col_key: string, c?: string) => string;
	setValue: (
		item_id: number | null,
		tab_name: string | null,
		input_name: string,
		value: string,
		rounding?: number,
		country_index?: number
	) => void;
	step?: number;
	disabled?: boolean;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	definition?: string;
	rounding?: number;
	calculationBase?: BaseColumns;
	setDataSaveStatus?: (s: DataSaveStatus) => void;
	tooltip?: boolean;
}) {
	const {
		defaultValue,
		tabName,
		name,
		country,
		definition,
		getValue,
		calculationBase,
		rounding,
		disabled
	} = props;

	const [value, setValue] = useState('');

	const [calculatedValue, setCalculatedValue] = useState('');

	const [edited, setEdited] = useState(false);

	const allCountries = useSelector((state: RootState) => state.user.allCountries);

	useEffect(() => {
		if (defaultValue !== undefined) {
			let val = defaultValue;
			if (typeof defaultValue === 'number' && rounding !== undefined) {
				val = defaultValue.toFixed(rounding);
			}
			setValue(val as string);
			if (
				calculationBase !== undefined &&
				definition !== undefined &&
				definition !== 'manual_input'
			) {
				setCalculatedValue(calculateValue(calculationBase, definition, rounding)?.toString());
				if (defaultValue === '$calc$') {
					setValue(calculateValue(calculationBase, definition, rounding)?.toString());
				}
			} else {
				setCalculatedValue(val as string);
			}
		} else if (getValue !== undefined) {
			const val = getValue(tabName ?? null, name, country);
			setValue(val);
			setCalculatedValue(val);
		}
	}, [country, defaultValue, getValue, name, tabName, calculationBase, definition, rounding]);

	useEffect(() => {
		setEdited(
			calculatedValue != value &&
				!(value == '' && parseFloat(calculatedValue) == 0) &&
				disabled !== undefined
		);
	}, [calculatedValue, value, disabled]);

	function onChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (defaultValue === undefined) {
			setValue(e.target.value);
		}
		props.onChange?.(e);
		let countryIndex: number | undefined = undefined;
		if (props.country !== undefined) {
			countryIndex = allCountries.findIndex((c) => c === props.country);
		}
		props.setValue(
			props.itemId ?? null,
			props.tabName ?? null,
			props.name,
			e.target.value,
			props.rounding,
			countryIndex
		);
	}

	return (
		<>
			{props.type !== 'checkbox' && (
				<>
					<NonFloatingInput
						name={
							props.template +
							'.' +
							props.productIndex +
							'.' +
							(props.country ?? '') +
							'.' +
							(props.itemId ?? '') +
							'.' +
							props.name
						}
						type={props.type === 'percentage' ? 'number' : props.type}
						defaultValue={value === '$calc$' ? '' : value}
						customClass={(props.customClass ?? '') + (edited ? ' border-warning' : '')}
						onChange={(e) => onChange(e)}
						hideErrorPlaceholder={true}
						required={props.required}
						step={props.step}
						min={props.min}
						disabled={props.disabled || props.tabName === 'tab_GP_calculator'}
						rounding={props.rounding}
						setDataSaveStatus={props.setDataSaveStatus}
						tooltip={props.tooltip}
					/>
					{props.type === 'percentage' && <div>%</div>}
				</>
			)}
			{props.type === 'checkbox' && (
				<Checkbox
					name={
						props.template +
						'.' +
						props.productIndex +
						'.' +
						(props.country ?? '') +
						'.' +
						(props.itemId ?? '') +
						'.' +
						props.name
					}
					defaultValue={value}
					customClass={props.customClass ?? ''}
					onChange={(e) => onChange(e)}
					required={props.required}
					disabled={props.disabled}
				/>
			)}
		</>
	);
}

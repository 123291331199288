import React, { useEffect } from 'react';
import Header from 'components/Header';
import Router from 'router/Router';
import { isUserLoggedIn, sendHttpRequest } from 'utils/httpRequestManager';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/store';
import { useNavigate } from 'react-router-dom';
import { setComponents } from 'state/componentSlice';
import { useSockets } from 'sockets/sockets';
import NotificationContainer from 'components/parts/NotificationContainer';
import i18n, { languages } from 'i18n';
import { setTranslations } from 'state/translationSlice';

export default function App() {
	useSockets();

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const components = useSelector((state: RootState) => state.components.components);

	const resources = useSelector((state: RootState) => state.translation.translations);

	useEffect(() => {
		for (const lang in resources) {
			i18n.addResourceBundle(lang, 'translation', resources[lang], undefined, true);
		}
	}, [resources]);

	useEffect(() => {
		if (isUserLoggedIn() && components.length === 0) {
			sendHttpRequest('GET', '/components', null, navigate, dispatch).then((response) => {
				dispatch(setComponents(response.data[0]));
			});
		}
	}, [components, dispatch, navigate]);

	useEffect(() => {
		languages.forEach((lang) => {
			sendHttpRequest('GET', '/translations/' + lang.lang, null, navigate, dispatch).then(
				(response) => {
					dispatch(setTranslations({ locale: lang.lang, translations: response.data }));
				}
			);
		});
	}, [dispatch, navigate]);

	return (
		<div className="App">
			<Header />
			<main className="container-fluid px-4">
				<Router />

				<NotificationContainer />
			</main>
		</div>
	);
}

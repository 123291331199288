import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TranslationsState {
	translations: {
		[key: string]: { [key: string]: string };
	};
}

const initialState: TranslationsState = {
	translations: {}
};

const translationSlice = createSlice({
	name: 'translation',
	initialState,
	reducers: {
		setTranslations(
			state,
			action: PayloadAction<{ locale: string; translations: { [key: string]: string } }>
		) {
			state.translations[action.payload.locale] = action.payload.translations;
		}
	}
});

export default translationSlice.reducer;
export const { setTranslations } = translationSlice.actions;

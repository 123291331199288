import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Input from 'components/Forms/FormComponents/Input';
import {
	DataSaveStatus,
	Product,
	PromotionStructure,
	StockItem
} from 'components/Forms/ProductsForm';
import PromotionStockItem from 'components/Forms/Promotion/PromotionStockItem';
import PromotionCell from 'components/Forms/Promotion/PromotionCell';
import { sendHttpRequest } from 'utils/httpRequestManager';
import { useNavigate } from 'react-router-dom';
import Loading from 'components/parts/Loading';
import { RootState } from 'state/store';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from 'components/Forms/FormComponents/Checkbox';
import { calculateValue, getBaseColumns } from 'utils/promotion';
import { Dropdown } from 'react-bootstrap';

export type StockItemOption = {
	c_item: string;
	n_item: string;
	i_item: string;
	srp: string;
};

export type BaseColumns = {
	country: string;
	columns: {
		COUNTRY: string;
		pcs_per_party: number;
		party_per_period: number;
		total_quantity: number;
		SRP: number;
		CSW: number;
		CEW: number;
		DSP: number;
		SP: number;
		RP: number;
		BP: number;
		RBP: number;
		COP: number;
		ESP: number;
		VAT: number;
		DSPR: number;
		DSPS: number;
		DSPH: number;
		DSPST: number;
		DSPCO: number;
		DSPES: number;
	};
};

export const baseColumnMap = new Map<string, string>([
	['pcs_per_party', 'pcs_per_party'],
	['party_per_period', 'party_per_period'],
	['total_quantity', 'total_quantity'],
	['srp', 'SRP'],
	['csw', 'CSW'],
	['cew', 'CEW'],
	['ds_price_retail', 'DSP'],
	['sample_price', 'SP'],
	['retail_price', 'RP'],
	['ds_price_SKU', 'BP'],
	['co_price', 'COP'],
	['eshop_price', 'ESP'],
	['VAT', 'VAT'],
	['ds_price_retail', 'DSPR'],
	['ds_price_sample', 'DSPS'],
	['ds_price_hoh', 'DSPH'],
	['ds_price_st', 'DSPST'],
	['ds_price_co', 'DSPCO'],
	['ds_price_eshop', 'DSPES']
]);

export default function PromotionProduct(props: {
	template: string;
	product: Product;
	retail_price: string;
	index: number;
	structures: PromotionStructure[];
	showStockItems: boolean;
	i_business: number;
	sumPrice: (product: Product, c?: string) => number;
	countries: string[];
	omitColumns: string[];
	setDataSaveStatus: (s: DataSaveStatus) => void;
	removeProduct: (productId: number) => void;
	listWeekFrom: string;
	listWeekTo: string;
}) {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [product, setProduct] = useState(props.product);
	const [hasInvalidComposition, setHasInvalidComposition] = useState(false);
	const [saveTimer, setSaveTimer] = useState(null as NodeJS.Timeout | null);
	const [savingLock, setSavingLock] = useState(false);

	const [namesLinked, setNamesLinked] = useState(
		props.product.countries.every((c) => c.product_name === props.product.countries[0].product_name)
	);

	const [baseColumns, setBaseColumns] = useState([] as BaseColumns[]);

	useEffect(() => {
		setProduct(props.product);
	}, [props.product]);

	useEffect(() => {
		setBaseColumns(
			getBaseColumns(props.countries, product, props.sumPrice, props.listWeekFrom, props.listWeekTo)
		);
	}, [product, props]);

	const stockItemOptions = useSelector((state: RootState) => state.stockItem.stockItems) ?? [];

	const structure = useMemo(() => {
		return props.structures.find((s) => s.i_template === product.i_template);
	}, [product.i_template, props.structures]);

	if (!structure) {
		return <></>;
	}

	const tabs = structure.tabs ?? [];

	const countriesTabs = structure.countries;

	function getValue(tab_key: string | null, col_key: string, c?: string) {
		if (tab_key == 'tab_GP_calculator') {
			return '$calc$';
		}
		if (c) {
			const country = product.countries?.find((pc) => pc.i_country === c);
			if (!country) {
				return '';
			}
			if (col_key == 'product_name') {
				return country?.product_name ?? '';
			}
			const tab = (country[tab_key as keyof typeof country] as object[])[0];
			return tab[col_key as keyof typeof tab] ?? '';
		}
		const values = product.countries?.map((pc) => {
			const tab = (pc[tab_key as keyof typeof pc] as object[])[0];
			const val = tab[col_key as keyof typeof tab] as number | string | null;
			if (typeof val === 'number') {
				return val.toFixed(2);
			}
			return tab[col_key as keyof typeof tab] ?? '';
		});
		if (values?.length && values.every((v) => v == values[0])) {
			if (col_key == 'retail_price' && product.retail_price !== values[0]) {
				setProduct({ ...product, retail_price: values[0] });
			}
			return values[0] ?? '';
		}
		return '';
	}

	function setValue(
		item_id: number | null,
		tab_name: string | null,
		input_name: string,
		value: string,
		rounding?: number,
		country_index?: number,
		save?: boolean
	) {
		let isCompositionModification = false;

		if (rounding !== undefined) {
			value = parseFloat('0' + value).toFixed(rounding);
		}

		const d = product as any;
		if (save !== true) {
			if (save === undefined) {
				save = true;
			}

			if (
				input_name === 'c_item' &&
				stockItemOptions.find((item) => item.c_item === value) === undefined
			) {
				save = false;
				setHasInvalidComposition(true);
			}

			if (input_name == 'quantity' && value == '0') {
				if (document.activeElement !== null) {
					(document.activeElement as HTMLElement).blur();
				}
				d.items = d.items.filter((_item: any, i: number) => i !== item_id);
				isCompositionModification = true;
			} else {
				if (country_index != undefined) {
					if (item_id == null && tab_name == null) {
						if (input_name == 'product_name' && namesLinked) {
							d.countries = d.countries.map((c: any) => {
								c.product_name = value;
								return c;
							});
						}
						d.countries[country_index][input_name] = value;
					} else if (tab_name != null && tab_name !== 'tab_GP_calculator') {
						d.countries[country_index][tab_name][0][input_name] = value;
					} else if (item_id != null) {
						d.items[item_id].countries[country_index][input_name] = value;
						if (input_name == 'retail_price') {
							isCompositionModification = true;
						}
					}
				} else {
					if (item_id == null && tab_name == null) {
						if (input_name == 'retail_price') {
							d.countries = d.countries.map((c: any) => {
								const crp =
									typeof c.tab_retail[0].retail_price === 'number'
										? c.tab_retail[0].retail_price.toFixed(2)
										: c.tab_retail[0].retail_price;
								const drp =
									typeof d.retail_price === 'number' ? d.retail_price.toFixed(2) : d.retail_price;
								if (crp == drp || crp == null) {
									c.tab_retail[0].retail_price = parseFloat(value).toFixed(2);
								}
								return c;
							});
						}
						d[input_name] = value;
					} else if (tab_name != null) {
						d.countries = d.countries.map((c: any) => {
							c[tab_name][0][input_name] = value;
							return c;
						});
					} else if (item_id != null) {
						const si = stockItemOptions.find((item) => item.c_item === value);
						if (input_name == 'c_item') {
							d.items[item_id]['n_item'] = si ? si.n_item : '';
							d.items[item_id]['i_item'] = si ? si.i_item : '';
							d.items[item_id]['srp'] = si ? si.srp : '';

							if (hasInvalidComposition && d.items.every((item: StockItem) => item.n_item !== '')) {
								setHasInvalidComposition(false);
							}
						}

						d.items[item_id][input_name] = value;

						isCompositionModification = true;
					}
				}
			}

			setProduct({ ...d });

			if (product.i_product == null && product.items.some((item) => item.i_item !== '')) {
				isCompositionModification = true;
				d.action = 'insert';
			}
		}

		if (saveTimer !== null) {
			clearTimeout(saveTimer);
		}

		const urlParams = new URLSearchParams(window.location.search);

		if ((product.i_product != null || d.action === 'insert') && save) {
			setSaveTimer(
				setTimeout(() => {
					props.setDataSaveStatus('Saving');
					if (isCompositionModification) {
						setSavingLock(true);
					}
					sendHttpRequest(
						'POST',
						'/promotion/' + (urlParams.get('i_list') ?? '1') + '/save',
						{ json: JSON.stringify(d), i_business: props.i_business },
						navigate,
						dispatch
					).then((response) => {
						setSaveTimer(null);
						if (isCompositionModification && response.data[0][0].prod_json !== undefined) {
							const data = JSON.parse(response.data[0][0].prod_json);
							if (data.retail_price !== undefined && data.retail_price !== null) {
								data.retail_price = (data.retail_price as number).toFixed(2);
							} else {
								data.retail_price = '';
							}
							if (data.items === undefined || data.items === null) {
								data.items = [];
							}
							for (const item of data.items) {
								if (item.srp !== undefined) {
									item.srp = ((item.srp ?? 0) as number).toFixed(2);
								}
							}
							data.id = product.id;
							setProduct(data);
						}
						setSavingLock(false);
						props.setDataSaveStatus('Data saved');
					});
				}, 1000)
			);
		}
	}

	function addItem() {
		product.items.push({
			c_item: '',
			i_item: '',
			n_item: '',
			quantity: 1,
			srp: '0.00',
			countries: []
		});
		setProduct({ ...product });
	}

	function calculateValues() {
		props.countries.forEach((country, country_index) => {
			const baseColumn = baseColumns.find((bc) => bc.country === country);
			countriesTabs.get(country)?.tabs.forEach((tab) => {
				tab.columns.forEach((col) => {
					if (col.definition && baseColumn && col.definition !== 'manual_input') {
						setValue(
							null,
							tab.key,
							col.key,
							calculateValue(baseColumn, col.definition, col.decimal),
							col.decimal,
							country_index,
							false
						);
					}
				});
			});
		});
		setValue(null, null, '', '', undefined, undefined, true);
	}

	function removeStockItem(itemIndex: number) {
		product.items.splice(itemIndex, 1);
		setProduct({ ...product });
		setValue(null, null, '', '', undefined, undefined, true);
	}

	return (
		<div className={'row mx-0 d-flex flex-nowrap' + (savingLock ? ' saving-lock' : '')}>
			{savingLock && <Loading />}
			<div className="promotion-sticky-left pe-2 py-2">
				<div className="products-wrapper">
					<div className="row mx-0">
						<div className="col-3 px-1">
							<Input
								name={props.template + '.' + props.index + '.product_code'}
								label={'Product code'}
								type="text"
								disabled={true}
								required={true}
								defaultValue={product.product_price_code}
								setDataSaveStatus={props.setDataSaveStatus}
							/>
						</div>

						<div className="col-3 px-1">
							{product.i_usg == 'CA' && (
								<Input
									name={props.template + '.' + props.index + '.product_short_code'}
									label={'Catalogue code'}
									type="text"
									required={true}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										setValue(null, null, 'product_short_code', e.target.value)
									}
									defaultValue={product.product_short_code ?? ''}
									setDataSaveStatus={props.setDataSaveStatus}
								/>
							)}
						</div>

						<div className="col-2 px-1">
							<Input
								name={props.template + '.' + props.index + '.srp_total'}
								label={'SRP'}
								type="number"
								customClass="text-end"
								min={0}
								disabled={true}
								step={0.01}
								defaultValue={props.sumPrice(product).toFixed(2)}
								required={true}
								setDataSaveStatus={props.setDataSaveStatus}
							/>
						</div>

						<div className="col-3 p-0">
							<div className="row m-0">
								<div className="col-6 px-1">
									<Input
										name={props.template + '.' + props.index + '.retail_price'}
										label={'Retail price'}
										type="number"
										customClass="text-end"
										min={0}
										step={0.01}
										disabled={savingLock || hasInvalidComposition || product.i_product == null}
										required={true}
										defaultValue={product.retail_price.toString()}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
											setValue(null, null, 'retail_price', e.target.value, 2)
										}
										rounding={2}
										setDataSaveStatus={props.setDataSaveStatus}
									/>
								</div>
								<div className="col-6 px-1">
									<label className="outside-floating-label">Base price</label>
								</div>
							</div>
						</div>
						<div className="col-1 d-flex justify-content-end pe-0">
							<Dropdown align="end">
								<Dropdown.Toggle variant="link" className="btn-icon fs-5">
									<i className="bi bi-three-dots-vertical" />
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item className="layout-selector" onClick={calculateValues}>
										<i className="bi bi-calculator me-2" />
										<span>Calculate</span>
									</Dropdown.Item>
									<Dropdown.Item
										onClick={() => {
											setSavingLock(true);
											props.removeProduct(props.product.id);
										}}>
										<i className="bi bi-trash3 me-2" />
										<span>Delete</span>
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>

						{props.countries.map((c, i) => (
							<div className="col-12 mt-2" key={i}>
								<div className="row align-items-center">
									<div className="col-1 px-2 country-label">{c}</div>
									<div className="col-7 px-1 position-relative">
										{i === 0 && (
											<button
												type="button"
												className="btn names-linked-toggle"
												onClick={() => setNamesLinked(!namesLinked)}>
												<i
													className={
														'fs-5 bi bi-link-45deg ' +
														(namesLinked ? 'color-primary-shade' : 'color-gray')
													}
												/>
											</button>
										)}
										<PromotionCell
											template={props.template}
											productIndex={props.index}
											productId={product.id}
											country={c}
											name={'product_name'}
											type={'text'}
											required={true}
											disabled={
												savingLock ||
												hasInvalidComposition ||
												product.i_product == null ||
												(namesLinked && i > 0)
											}
											getValue={getValue}
											setValue={setValue}
											setDataSaveStatus={props.setDataSaveStatus}
										/>
									</div>
									<div className="col-3 p-0">
										<div className="row m-0">
											<div className="col-6 px-1">
												<PromotionCell
													template={props.template}
													productIndex={props.index}
													productId={product.id}
													name="country_retail_price"
													type="number"
													customClass="text-end"
													required={true}
													setValue={setValue}
													disabled={true}
													setDataSaveStatus={props.setDataSaveStatus}
													defaultValue={product.items
														.map((item) => {
															const itemCountry = item.countries?.find((pc) => pc.i_country === c);
															return (
																parseInt(item.quantity.toString()) *
																parseFloat((itemCountry?.retail_price ?? 0).toString())
															);
														})
														.reduce((acc, item) => acc + item, 0)
														.toFixed(2)}
												/>
											</div>
											<div className="col-6 px-1">
												<PromotionCell
													template={props.template}
													productIndex={props.index}
													productId={product.id}
													name="country_base_price"
													type="number"
													customClass="text-end"
													required={true}
													setValue={setValue}
													disabled={true}
													setDataSaveStatus={props.setDataSaveStatus}
													defaultValue={product.items
														.map((item) => {
															const itemCountry = item.countries?.find((pc) => pc.i_country === c);
															return (
																parseInt(item.quantity.toString()) *
																parseFloat((itemCountry?.base_price ?? 0).toString())
															);
														})
														.reduce((acc, item) => acc + item, 0)
														.toFixed(2)}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						))}

						{props.showStockItems && (
							<div className="col-12 mt-2 mx-0">
								<div className="row stock-item-header-labels">
									<div className="col-1 ps-3 pe-0">Qty</div>
									<div className="col-5 px-3 d-flex justify-content-between">
										Stock item
										<button
											type="button"
											className="link"
											disabled={savingLock}
											onClick={() => {
												if (!savingLock) {
													addItem();
												}
											}}>
											+&nbsp;Add
										</button>
									</div>
									{/*<div className="col-4" />*/}
								</div>

								{product.items.map((item, i) => (
									<PromotionStockItem
										key={i}
										template={props.template}
										productId={product.id}
										productIndex={props.index}
										i={i}
										item={item}
										disabled={savingLock}
										setValue={setValue}
										setDataSaveStatus={props.setDataSaveStatus}
										removeStockItem={removeStockItem}
										countries={props.countries}
									/>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="col-7 products-detail-wrapper ps-2 py-2">
				<div className="tab-wrapper mt-0">
					<div className="d-flex p-0 m-0">
						{tabs.map((t, i) => (
							<Fragment key={i}>
								{t.columns.map((col, col_index) => (
									<Fragment key={col_index}>
										{!props.omitColumns.includes(col.key) && (
											<div
												className={'col px-1' + (col.type == 'datetime-local' ? ' datetime' : '')}>
												{col.type !== 'checkbox' && (
													<>
														<Input
															label={col.name}
															name={props.template + '.' + props.index + '.' + i + '.' + col.key}
															type={col.type}
															customClass={
																['number', 'percentage'].includes(col.type) ? 'text-end' : ''
															}
															step={['number', 'percentage'].includes(col.type) ? 0.01 : undefined}
															defaultValue={getValue(t.key, col.key)}
															required={false}
															disabled={
																savingLock ||
																hasInvalidComposition ||
																product.i_product == null ||
																col.disabled ||
																t.key === 'tab_GP_calculator'
															}
															rounding={col.decimal}
															onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
																setValue(null, t.key, col.key, e.target.value, col.decimal)
															}
															setDataSaveStatus={props.setDataSaveStatus}
														/>
														{col.type === 'percentage' && <div>%</div>}
													</>
												)}
												{col.type === 'checkbox' && (
													<Checkbox
														label={col.name}
														name={props.template + '.' + props.index + '.' + i + '.' + col.key}
														defaultValue={getValue(t.key, col.key)}
														required={false}
														disabled={
															savingLock ||
															hasInvalidComposition ||
															product.i_product == null ||
															col.disabled
														}
														onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
															setValue(null, t.key, col.key, e.target.value)
														}
													/>
												)}
											</div>
										)}
									</Fragment>
								))}
							</Fragment>
						))}
					</div>
					{props.countries.map((c, c_index) => (
						<div key={c_index} className="d-flex pt-2 px-0">
							{countriesTabs.get(c)?.tabs.map((t, i) => (
								<Fragment key={i}>
									{t.columns.map((col, col_index) => (
										<Fragment key={col_index}>
											{!props.omitColumns.includes(col.key) && (
												<div
													className={
														'col px-1' + (col.type == 'datetime-local' ? ' datetime' : '')
													}>
													<PromotionCell
														template={props.template}
														productIndex={props.index}
														productId={product.id}
														country={c}
														name={col.key}
														type={col.type}
														step={['number', 'percentage'].includes(col.type) ? 0.01 : undefined}
														required={false}
														disabled={
															savingLock ||
															hasInvalidComposition ||
															product.i_product == null ||
															col.disabled
														}
														definition={col.definition}
														rounding={col.decimal}
														calculationBase={baseColumns.find((bc) => bc.country === c)}
														defaultValue={getValue(t.key, col.key, c)}
														setValue={setValue}
														customClass={
															col.type == 'number'
																? 'text-end'
																: col.type == 'percentage'
																	? 'text-end percentage'
																	: ''
														}
														tabName={t.key}
														setDataSaveStatus={props.setDataSaveStatus}
													/>
												</div>
											)}
										</Fragment>
									))}
								</Fragment>
							))}
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

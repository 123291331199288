import React from 'react';
import { Link } from 'react-router-dom';

export default function Home() {
	return (
		<>
			<h1>Homepage</h1>
			<div className="py-5">
				<Link to={'/product-import'} className="btn btn-primary">
					Product import
				</Link>
			</div>
		</>
	);
}

import React, { useEffect, useState } from 'react';
import { FieldError, FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { ValidationRule } from 'react-hook-form/dist/types/validator';
import { isNumber } from 'utils/promotion';
import { DataSaveStatus } from 'components/Forms/ProductsForm';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function NonFloatingInput<T extends FieldValues>(props: {
	inputId?: string;
	type: string;
	list?: string;
	name?: string;
	customClass?: string;
	label?: string;
	register?: UseFormRegister<T>;
	error?: FieldError;
	defaultValue?: string;
	required: boolean;
	step?: number;
	min?: number;
	max?: number;
	minLength?: number;
	maxLength?: number;
	pattern?: ValidationRule<RegExp>;
	patternDescription?: string;
	disabled?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onKeyDown?: (event: React.KeyboardEvent) => void;
	autoComplete?: string;
	customError?: string;
	hideErrorPlaceholder?: boolean;
	rounding?: number;
	setDataSaveStatus?: (s: DataSaveStatus) => void;
	tooltip?: boolean;
}) {
	const inputId = props.inputId ?? props.name;

	const [inputValue, setInputValue] = useState('');
	const [inputChanged, setInputChanged] = useState(false);
	const [lastEvent, setLastEvent] = useState(null as React.ChangeEvent<HTMLInputElement> | null);

	useEffect(() => {
		setInputChanged(false);
	}, [props.defaultValue]);

	if (props.register === undefined) {
		return (
			<OverlayTrigger
				overlay={
					props.tooltip ? <Tooltip id={inputId + '-tooltip'}>{props.defaultValue}</Tooltip> : <></>
				}>
				<input
					disabled={props.disabled}
					type={['number', 'percentage'].includes(props.type) ? 'text' : props.type}
					step={props.step}
					id={inputId}
					value={!inputChanged ? props.defaultValue : inputValue}
					maxLength={props.maxLength}
					list={props.list}
					className={
						'form-control ' +
						(props.customClass ?? '') +
						(props.error || props.customError ? ' is-invalid' : '')
					}
					onInput={(e) => {
						const event = e as React.ChangeEvent<HTMLInputElement>;
						if (['number', 'percentage'].includes(props.type)) {
							event.target.value = event.target.value.replaceAll(',', '.');
						}
						if (
							!['number', 'percentage'].includes(props.type) ||
							isNumber(event.target.value, props.rounding)
						) {
							setInputChanged(true);
							setInputValue(event.target.value);
							setLastEvent(event);
							props.setDataSaveStatus?.('Save data');
						}
					}}
					autoComplete={props.autoComplete}
					onKeyDown={props.onKeyDown}
					onBlur={() => {
						if (lastEvent !== null) {
							if (
								isNumber(inputValue, props.rounding) &&
								['number', 'percentage'].includes(props.type)
							) {
								setInputValue(parseFloat(inputValue).toFixed(props.rounding));
							}
							if (
								isNumber(lastEvent.target.value, props.rounding) &&
								['number', 'percentage'].includes(props.type)
							) {
								lastEvent.target.value = parseFloat(lastEvent.target.value).toFixed(2);
							}
							props.onChange?.(lastEvent);
						}
					}}
				/>
			</OverlayTrigger>
		);
	}

	return (
		<>
			<input
				{...props.register(props.name as Path<T>, {
					required: props.required,
					min: props.min,
					max: props.max,
					minLength: props.minLength,
					maxLength: props.maxLength,
					pattern: props.pattern
				})}
				disabled={props.disabled}
				type={props.type}
				step={props.step}
				id={inputId}
				value={props.defaultValue}
				maxLength={props.maxLength}
				className={
					'form-control ' +
					(props.customClass ?? '') +
					(props.error || props.customError ? ' is-invalid' : '')
				}
				onInput={props.onChange}
				autoComplete={props.autoComplete}
				onKeyDown={props.onKeyDown}
			/>
			{props.label && <label htmlFor={inputId}>{props.label}</label>}
			{!props.error && !props.customError && !props.hideErrorPlaceholder && (
				<div className="invalid-feedback error-placeholder d-block">&nbsp;</div>
			)}
			{props.error && props.error.type == 'required' && (
				<div className="invalid-feedback">{props.label} is required.</div>
			)}
			{props.error && props.error.type == 'min' && (
				<div className="invalid-feedback">
					{props.label} must be at least {props.min}.
				</div>
			)}
			{props.error && props.error.type == 'max' && (
				<div className="invalid-feedback">
					{props.label} must be at most {props.max}.
				</div>
			)}
			{props.error && props.error.type == 'minLength' && (
				<div className="invalid-feedback">
					{props.label} must be at least {props.minLength} characters long.
				</div>
			)}
			{props.error && props.error.type == 'maxLength' && (
				<div className="invalid-feedback">
					{props.label} must be at most {props.maxLength} characters long.
				</div>
			)}
			{props.customError && <div className="invalid-feedback">{props.customError}</div>}
			{props.error && props.error.type == 'pattern' && (
				<div className="invalid-feedback">
					{props.label} {props.patternDescription}.
				</div>
			)}
		</>
	);
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NotificationsState {
	notifications: NotificationBlock[];
}

export type NotificationBlock = {
	id: string;
	message: string;
	severity: 'info' | 'success' | 'warning' | 'error';
	rq?: string;
};

const initialState: NotificationsState = {
	notifications: []
};

const notificationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {
		addNotification(state, action: PayloadAction<NotificationBlock>) {
			state.notifications = [...state.notifications, action.payload];
		},
		removeNotification(state, action: PayloadAction<string>) {
			state.notifications = state.notifications.filter((n) => n.id !== action.payload);
		}
	}
});

export default notificationSlice.reducer;
export const { addNotification, removeNotification } = notificationSlice.actions;

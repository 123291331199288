import React, { useRef, useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FieldError, FieldValues, UseFormRegister } from 'react-hook-form';

export default function Checkbox<T extends FieldValues>(props: {
	inputId?: string;
	name?: string;
	customClass?: string;
	label?: string;
	register?: UseFormRegister<T>;
	error?: FieldError;
	defaultValue?: string | number;
	required: boolean;
	disabled?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	customError?: string;
}) {
	const inputId = props.inputId ?? props.name;

	const labelRef = useRef<HTMLLabelElement>(null);
	const checkboxRef = useRef<HTMLInputElement>(null);
	const [labelOverflowing, setLabelOverflowing] = useState(false);

	useEffect(() => {
		const label = labelRef.current;
		if (label) {
			const isOverflow = label.scrollWidth >= label.clientWidth;
			setLabelOverflowing(isOverflow);
		}
	}, []);

	function toggle() {
		const event = new Event('input', { bubbles: true });
		(checkboxRef.current as HTMLInputElement).value =
			props.defaultValue?.toString() == '1' ? '0' : '1';
		(checkboxRef.current as HTMLInputElement).dispatchEvent(event);
	}

	return (
		<div className="h-100">
			<input
				ref={checkboxRef}
				id={inputId}
				name={props.name}
				className="d-none"
				type="hidden"
				required={props.required}
				disabled={props.disabled}
				value={props.defaultValue}
				onInput={props.onChange}
			/>
			<div className={props.label ? 'form-floating' : 'h-100'}>
				<div className="form-control h-100 cursor-pointer text-center" onClick={toggle}>
					{props.defaultValue?.toString() === '1' && <i className="bi bi-check-square" />}
					{props.defaultValue?.toString() === '0' && (
						<i className="bi bi-square color-primary-shade" />
					)}
					{(props.defaultValue === '' || props.defaultValue === null) && (
						<i className="bi bi-square color-gray-tint" />
					)}
				</div>
				{props.label && (
					<>
						<label ref={labelRef} htmlFor={inputId}>
							{props.label}
						</label>
						{labelOverflowing && (
							<OverlayTrigger overlay={<Tooltip id={inputId + '-tooltip'}>{props.label}</Tooltip>}>
								<a href="#" className="tooltip-trigger" />
							</OverlayTrigger>
						)}
					</>
				)}
			</div>
		</div>
	);
}
